import React from 'react';

function Eggthemighty (props) {

    return (
        <></>
        // <svg className={`vr-logo ${props.size}`} viewBox="0 0 47 50" fill="none" xmlns="http://www.w3.org/2000/svg">
        //     <path fillRule="evenodd" clipRule="evenodd" d="M1.3764 26.1842C1.3764 38.6083 10.9498 48.6963 22.7787 48.6963C34.6076 48.6963 44.1809 38.6083 44.1809 26.1842C44.1809 13.7602 34.6076 3.67228 22.7787 3.67228C10.9498 3.67228 1.3764 13.7602 1.3764 26.1842ZM22.7787 48.3289C11.1702 48.3289 1.74382 38.4232 1.74382 26.1842C1.74382 13.9452 11.1702 4.0397 22.7787 4.0397C34.3872 4.0397 43.8135 13.9452 43.8135 26.1842C43.8135 38.4233 34.3872 48.3289 22.7787 48.3289Z" fill="#231F20"/>
        //     <path fillRule="evenodd" clipRule="evenodd" d="M0 26.0325C0 39.2671 10.1962 50 22.7786 50C35.3612 50 45.5573 39.2671 45.5573 26.0325C45.5573 12.7978 35.3612 2.06493 22.7786 2.06493C10.1962 2.06493 0 12.7978 0 26.0325ZM22.7786 49.9081C10.2513 49.9081 0.0918547 39.2208 0.0918547 26.0325C0.0918547 12.8441 10.2513 2.15678 22.7786 2.15678C35.3061 2.15678 45.4655 12.8441 45.4655 26.0325C45.4655 39.2208 35.3061 49.9081 22.7786 49.9081Z" fill="#BF1B2C"/>
        //     <path fillRule="evenodd" clipRule="evenodd" d="M2.73932 23.1598C2.73932 35.9485 12.5919 46.3198 24.7505 46.3198C36.9091 46.3198 46.7617 35.9485 46.7617 23.1598C46.7617 10.3711 36.9091 -0.000114441 24.7505 -0.000114441C12.5919 -0.000114441 2.73932 10.3711 2.73932 23.1598ZM24.7505 46.228C12.647 46.228 2.83117 35.9022 2.83117 23.1598C2.83117 10.4174 12.647 0.0917397 24.7505 0.0917397C36.854 0.0917397 46.6698 10.4174 46.6698 23.1598C46.6698 35.9022 36.854 46.228 24.7505 46.228Z" fill="#231F20"/>
        //     <path d="M11.8124 22.9561L10.135 15.5204H11.8439L12.3785 18.5763C12.5358 19.4699 12.6826 20.4297 12.7978 21.1909H12.8189C12.9341 20.3745 13.1019 19.481 13.2801 18.5543L13.8777 15.5204H15.5761L16.1422 18.6535C16.2994 19.5251 16.4148 20.3194 16.5196 21.1468H16.5406C16.6454 20.3194 16.8027 19.4479 16.9495 18.5543L17.5261 15.5204H19.1511L17.3268 22.9561H15.5971L14.9994 19.7567C14.8632 19.0066 14.7478 18.3116 14.664 17.4621H14.6431C14.5173 18.3005 14.4019 19.0066 14.2341 19.7678L13.5632 22.9561H11.8124Z" fill="#ED1C24"/>
        //     <path d="M22.9772 21.7427C24.057 21.7427 24.6756 20.6726 24.6756 19.2164C24.6756 17.8815 24.078 16.7342 22.9772 16.7342C21.8764 16.7342 21.2579 17.8595 21.2579 19.2495C21.2579 20.6506 21.8974 21.7427 22.9668 21.7427H22.9772ZM22.8933 23.0776C20.8071 23.0776 19.5699 21.4118 19.5699 19.2936C19.5699 17.0652 20.9329 15.3994 23.0087 15.3994C25.1788 15.3994 26.3635 17.1093 26.3635 19.1612C26.3635 21.6104 24.9692 23.0776 22.9038 23.0776H22.8933Z" fill="#00ADEF"/>
        //     <path d="M28.4812 22.9561L26.8038 15.5204H28.5127L29.0473 18.5763C29.2045 19.4699 29.3514 20.4297 29.4667 21.1909H29.4876C29.6029 20.3745 29.7706 19.481 29.949 18.5543L30.5464 15.5204H32.2449L32.811 18.6535C32.9682 19.5251 33.0835 20.3194 33.1884 21.1468H33.2094C33.3142 20.3194 33.4714 19.4479 33.6182 18.5543L34.1948 15.5204H35.8198L33.9956 22.9561H32.2658L31.6683 19.7567C31.5319 19.0066 31.4166 18.3116 31.3328 17.4621H31.3117C31.186 18.3005 31.0707 19.0066 30.903 19.7678L30.232 22.9561H28.4812Z" fill="#40B93C"/>
        //     <path d="M11.346 37.4932L10.8704 35.9262H11.0013L11.2632 36.8096C11.3219 37.012 11.3781 37.2003 11.4128 37.3466H11.4182C11.4476 37.2003 11.5118 37.0142 11.5838 36.8096L11.8778 35.9262H12.0008L12.268 36.8027C12.3269 36.9933 12.3856 37.1863 12.415 37.3466H12.4204C12.4605 37.177 12.5193 37.0072 12.5834 36.8074L12.8801 35.9262H13.011L12.4711 37.4932H12.3482L12.0783 36.5911C12.0061 36.3609 11.9634 36.2075 11.9393 36.061H11.934C11.9046 36.2052 11.8538 36.3609 11.7736 36.5888L11.4663 37.4932H11.346Z" fill="#231F20"/>
        //     <path d="M13.8346 36.8955C13.5539 36.8886 13.1851 36.9257 13.1851 37.1931C13.1851 37.3535 13.3081 37.4256 13.4417 37.4256C13.6555 37.4256 13.7784 37.3116 13.8212 37.2024C13.8319 37.1792 13.8346 37.1535 13.8346 37.135V36.8955ZM13.9629 37.228C13.9629 37.3163 13.9655 37.407 13.9815 37.493H13.8639L13.8452 37.3326H13.8399C13.7757 37.4186 13.6341 37.5186 13.4283 37.5186C13.1691 37.5186 13.0515 37.3605 13.0515 37.2117C13.0515 36.9536 13.3107 36.7979 13.8346 36.8025V36.7723C13.8346 36.663 13.8105 36.4421 13.5059 36.4445C13.3936 36.4445 13.276 36.47 13.1824 36.5282L13.1424 36.4468C13.26 36.3794 13.4042 36.3515 13.5192 36.3515C13.8906 36.3515 13.9629 36.5933 13.9629 36.7909V37.228Z" fill="#231F20"/>
        //     <path d="M14.3772 35.8402H14.5055V37.4932H14.3772V35.8402Z" fill="#231F20"/>
        //     <path d="M15.0694 36.9142H15.0748C15.1068 36.8794 15.1576 36.8282 15.1977 36.791L15.652 36.3772H15.8071L15.2885 36.8398L15.8792 37.4932H15.7242L15.2003 36.9073L15.0694 37.0282V37.4932H14.9411V35.8402H15.0694V36.9142Z" fill="#231F20"/>
        //     <path d="M17.3386 37.4256C17.7662 37.4256 18.0041 37.0885 18.0041 36.7002C18.0041 36.37 17.7957 35.9934 17.344 35.9934C16.887 35.9934 16.6758 36.3561 16.6758 36.7211C16.6758 37.0745 16.9111 37.4256 17.3359 37.4256H17.3386ZM17.3306 37.5186C16.887 37.5186 16.5422 37.214 16.5422 36.7257C16.5422 36.212 16.9003 35.9004 17.352 35.9004C17.8063 35.9004 18.1377 36.2097 18.1377 36.6933C18.1377 37.2466 17.7475 37.5186 17.3333 37.5186H17.3306Z" fill="#231F20"/>
        //     <path d="M18.4533 36.6446C18.4533 36.5422 18.4507 36.4655 18.4427 36.3772H18.5629L18.5709 36.5771H18.5763C18.6458 36.4516 18.8088 36.3516 19.0172 36.3516C19.1401 36.3516 19.4663 36.405 19.4663 36.8235V37.4932H19.3379V36.8329C19.3379 36.6283 19.2471 36.4446 18.9825 36.4446C18.8035 36.4446 18.6485 36.5563 18.5977 36.7027C18.5896 36.7283 18.5817 36.7655 18.5817 36.8003V37.4932H18.4533V36.6446Z" fill="#231F20"/>
        //     <path d="M19.886 35.8402H20.0143V37.4932H19.886V35.8402Z" fill="#231F20"/>
        //     <path d="M20.4527 36.3772H20.581V37.4932H20.4527V36.3772ZM20.5115 36.1541C20.4527 36.1541 20.4126 36.1099 20.4126 36.0588C20.4126 36.0077 20.4581 35.9611 20.5169 35.9611C20.5757 35.9611 20.6185 36.0053 20.6185 36.0588C20.6185 36.1099 20.5783 36.1541 20.5142 36.1541H20.5115Z" fill="#231F20"/>
        //     <path d="M21.0114 36.6446C21.0114 36.5422 21.0087 36.4655 21.0006 36.3772H21.121L21.1288 36.5771H21.1342C21.2037 36.4516 21.3668 36.3516 21.5752 36.3516C21.6982 36.3516 22.0243 36.405 22.0243 36.8235V37.4932H21.896V36.8329C21.896 36.6283 21.8051 36.4446 21.5405 36.4446C21.3614 36.4446 21.2065 36.5563 21.1556 36.7027C21.1476 36.7283 21.1397 36.7655 21.1397 36.8003V37.4932H21.0114V36.6446Z" fill="#231F20"/>
        //     <path d="M23.2805 36.8354C23.2831 36.6797 23.2082 36.4448 22.8982 36.4448C22.615 36.4448 22.4947 36.6634 22.4706 36.8354H23.2805ZM22.4627 36.9237C22.4627 37.2795 22.6872 37.4213 22.9517 37.4213C23.1388 37.4213 23.235 37.3887 23.3071 37.3586L23.3392 37.4445C23.2911 37.4678 23.1601 37.5166 22.9356 37.5166C22.5668 37.5166 22.337 37.2841 22.337 36.9563C22.337 36.5843 22.5829 36.3519 22.9143 36.3519C23.3339 36.3519 23.4141 36.696 23.4141 36.8564C23.4141 36.8866 23.4141 36.9029 23.4088 36.9237H22.4627Z" fill="#231F20"/>
        //     <path d="M24.9965 36.8955C24.7159 36.8886 24.347 36.9257 24.347 37.1931C24.347 37.3535 24.47 37.4256 24.6036 37.4256C24.8174 37.4256 24.9404 37.3116 24.9832 37.2024C24.9938 37.1792 24.9965 37.1535 24.9965 37.135V36.8955ZM25.1248 37.228C25.1248 37.3163 25.1274 37.407 25.1435 37.493H25.0259L25.0072 37.3326H25.0019C24.9377 37.4186 24.7961 37.5186 24.5902 37.5186C24.331 37.5186 24.2134 37.3605 24.2134 37.2117C24.2134 36.9536 24.4727 36.7979 24.9965 36.8025V36.7723C24.9965 36.663 24.9724 36.4421 24.6678 36.4445C24.5555 36.4445 24.4379 36.47 24.3444 36.5282L24.3043 36.4468C24.4219 36.3794 24.5662 36.3515 24.6811 36.3515C25.0526 36.3515 25.1248 36.5933 25.1248 36.7909V37.228Z" fill="#231F20"/>
        //     <path d="M25.5393 36.6446C25.5393 36.5422 25.5366 36.4655 25.5286 36.3772H25.6489L25.6568 36.5771H25.6622C25.7316 36.4516 25.8947 36.3516 26.1032 36.3516C26.2261 36.3516 26.5522 36.405 26.5522 36.8235V37.4932H26.4239V36.8329C26.4239 36.6283 26.333 36.4446 26.0684 36.4446C25.8893 36.4446 25.7344 36.5563 25.6836 36.7027C25.6755 36.7283 25.6676 36.7655 25.6676 36.8003V37.4932H25.5393V36.6446Z" fill="#231F20"/>
        //     <path d="M26.8945 36.3771L27.2312 37.1072C27.2659 37.1861 27.3006 37.2768 27.3247 37.3466H27.3301C27.3568 37.2792 27.3888 37.1907 27.4289 37.1001L27.7416 36.3771H27.8779L27.4931 37.2094C27.3381 37.5558 27.2285 37.7628 27.0602 37.8906C26.9586 37.965 26.8651 37.9976 26.8357 38.0068L26.7956 37.9138C26.8597 37.8929 26.9425 37.8533 27.02 37.7906C27.0842 37.7349 27.1697 37.6418 27.2338 37.5093C27.2446 37.486 27.2499 37.4722 27.2499 37.4605C27.2499 37.4512 27.2446 37.4349 27.2338 37.4117L26.7582 36.3771H26.8945Z" fill="#231F20"/>
        //     <path d="M28.4206 37.4932L27.9449 35.9262H28.0758L28.3377 36.8096C28.3965 37.012 28.4526 37.2003 28.4874 37.3466H28.4928C28.5222 37.2003 28.5863 37.0142 28.6585 36.8096L28.9523 35.9262H29.0754L29.3427 36.8027C29.4014 36.9933 29.4601 37.1863 29.4896 37.3466H29.495C29.535 37.177 29.5939 37.0072 29.6579 36.8074L29.9546 35.9262H30.0856L29.5456 37.4932H29.4228L29.1529 36.5911C29.0807 36.3609 29.0379 36.2075 29.0139 36.061H29.0085C28.9791 36.2052 28.9283 36.3609 28.8482 36.5888L28.5409 37.4932H28.4206Z" fill="#231F20"/>
        //     <path d="M30.3132 35.8402H30.4415V36.5771H30.4468C30.4842 36.5143 30.5457 36.4563 30.6205 36.4167C30.6953 36.3772 30.7835 36.3516 30.8797 36.3516C31 36.3516 31.3261 36.4051 31.3261 36.8212V37.4932H31.1978V36.8329C31.1978 36.633 31.1069 36.4492 30.8449 36.4492C30.6632 36.4492 30.5136 36.5632 30.4574 36.7003C30.4441 36.7352 30.4415 36.7655 30.4415 36.8119V37.4932H30.3132V35.8402Z" fill="#231F20"/>
        //     <path d="M32.5822 36.8354C32.5848 36.6797 32.51 36.4448 32.2 36.4448C31.9167 36.4448 31.7965 36.6634 31.7724 36.8354H32.5822ZM31.7643 36.9237C31.7643 37.2795 31.9889 37.4213 32.2534 37.4213C32.4406 37.4213 32.5367 37.3887 32.6089 37.3586L32.641 37.4445C32.5929 37.4678 32.4619 37.5166 32.2374 37.5166C31.8686 37.5166 31.6388 37.2841 31.6388 36.9563C31.6388 36.5843 31.8847 36.3519 32.2161 36.3519C32.6357 36.3519 32.7158 36.696 32.7158 36.8564C32.7158 36.8866 32.7158 36.9029 32.7105 36.9237H31.7643Z" fill="#231F20"/>
        //     <path d="M33.0395 36.712C33.0395 36.6004 33.0368 36.4865 33.0288 36.3772H33.1464L33.1518 36.6027H33.1597C33.2159 36.4609 33.3495 36.3516 33.5232 36.3516C33.5419 36.3516 33.5606 36.354 33.5766 36.3563V36.4609C33.5579 36.4587 33.5392 36.4587 33.5151 36.4587C33.3388 36.4587 33.2132 36.5911 33.1784 36.7701C33.1705 36.8027 33.1678 36.8398 33.1678 36.8771V37.4932H33.0395V36.712Z" fill="#231F20"/>
        //     <path d="M34.6378 36.8354C34.6404 36.6797 34.5656 36.4448 34.2556 36.4448C33.9723 36.4448 33.8521 36.6634 33.828 36.8354H34.6378ZM33.82 36.9237C33.82 37.2795 34.0445 37.4213 34.309 37.4213C34.4962 37.4213 34.5923 37.3887 34.6645 37.3586L34.6966 37.4445C34.6485 37.4678 34.5175 37.5166 34.293 37.5166C33.9242 37.5166 33.6944 37.2841 33.6944 36.9563C33.6944 36.5843 33.9403 36.3519 34.2717 36.3519C34.6913 36.3519 34.7714 36.696 34.7714 36.8564C34.7714 36.8866 34.7714 36.9029 34.7661 36.9237H33.82Z" fill="#231F20"/>
        //     <path d="M13.8685 31.4617C14.1604 31.6324 14.8308 31.9169 15.5012 31.9169C16.3554 31.9169 16.7879 31.4845 16.7879 30.9383C16.7879 30.1987 16.0959 29.8687 15.3823 29.8687H14.7119V28.6285H15.3498C15.9013 28.6285 16.5933 28.4009 16.5933 27.7751C16.5933 27.3313 16.2581 27.0013 15.5553 27.0013C14.9822 27.0013 14.3766 27.263 14.0955 27.4451L13.7603 26.1934C14.182 25.909 15.0146 25.6359 15.9337 25.6359C17.4259 25.6359 18.2585 26.4665 18.2585 27.4792C18.2585 28.2643 17.8368 28.8902 16.9718 29.1973V29.2201C17.8152 29.3794 18.4964 30.0507 18.4964 31.0294C18.4964 32.3265 17.4043 33.2822 15.6201 33.2822C14.7119 33.2822 13.9441 33.032 13.5332 32.7702L13.8685 31.4617Z" fill="#231F20"/>
        //     <path d="M28.1414 31.9737C28.7794 31.9737 29.1254 31.1316 29.1254 29.4476C29.1254 27.8091 28.7902 26.9444 28.1306 26.9444C27.5143 26.9444 27.1249 27.775 27.1249 29.4476C27.1142 31.1544 27.5034 31.9737 28.1306 31.9737H28.1414ZM28.109 33.2822C26.2924 33.2822 25.4922 31.564 25.4814 29.4704C25.4814 27.3199 26.3357 25.6358 28.1522 25.6358C30.0229 25.6358 30.7691 27.3995 30.7691 29.4249C30.7691 31.7006 29.8931 33.2822 28.1198 33.2822H28.109Z" fill="#231F20"/>
        //     <path fillRule="evenodd" clipRule="evenodd" d="M31.4001 26.3415C31.4001 26.7159 31.6891 27.0234 32.0504 27.0234C32.4118 27.0234 32.7008 26.7159 32.7008 26.3415C32.7008 25.9671 32.4118 25.6595 32.0504 25.6595C31.6891 25.6595 31.4001 25.9671 31.4001 26.3415ZM32.0504 26.9316C31.7442 26.9316 31.4919 26.6696 31.4919 26.3415C31.4919 26.0133 31.7442 25.7514 32.0504 25.7514C32.3567 25.7514 32.6089 26.0133 32.6089 26.3415C32.6089 26.6696 32.3567 26.9316 32.0504 26.9316Z" fill="#231F20"/>
        //     <path d="M22.2268 32.0078C22.7999 32.0078 23.1675 31.4503 23.1675 30.6993C23.1675 30.0166 22.8215 29.4249 22.1186 29.4249C21.6753 29.4249 21.3076 29.7094 21.1563 30.0735C21.1022 30.1759 21.0805 30.3124 21.0805 30.5173C21.113 31.3024 21.4699 32.0078 22.216 32.0078H22.2268ZM24.065 26.9899C23.8812 26.9785 23.6865 26.9899 23.427 27.0126C21.9673 27.1264 21.3185 27.9229 21.1347 28.7763H21.1671C21.5131 28.4123 22.0105 28.196 22.6593 28.196C23.838 28.196 24.8328 29.0722 24.8328 30.6083C24.8328 32.0761 23.7623 33.2822 22.2484 33.2822C20.3669 33.2822 19.4478 31.8144 19.4478 30.0507C19.4478 28.6626 19.9344 27.5019 20.7021 26.751C21.3941 26.0796 22.3133 25.7269 23.4054 25.67C23.7082 25.6358 23.9136 25.6473 24.065 25.6586V26.9899Z" fill="#231F20"/>
        // </svg>    
    );
}

export default Eggthemighty;