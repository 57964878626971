import React from "react";
import Modals from 'react-modal';
import SlidingPane from 'react-sliding-pane';
import axios from 'axios';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import Placeholder from '../images/placeholder.png';
import CloseIcon from '@material-ui/icons/Close';
//import Hallnumber from './floorplans/HallNumber';
import Hallwithbooths from './floorplans/HallwithBooths';
import Hallnine from '../components/floorplans/Hall9SVG';
import Eggthemighty from './Egglogo';
import wow360 from '../images/g18.png';
import { Productcategory, Productlist, Awardslist } from './ProductList';

class Floor extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      thehall: {},
      isloaded: false,
      isPaneOpenBottom: false,
      companyName: 'undefined',
      companyVR: 0,
      companyLogo: '',
      companyHall: '',
      companyDesc: '',
      companyProducts: '',
      companyVideos: '',
      companySite: '',
      companyWhatsapp: '',
      companyEmail: '',
      companyAwards: '',
      productCategories: '',
      companyShort: '',
      redirect: 0,
      wechatBold: '',
    };
  }

  handleClick = (exid) => {
    axios.get(`${process.env.REACT_APP_API_HOST}/api/exhibitors/` + exid)
      .then(res => {
        const exdata = res.data;
        var zproducts = exdata.products;
        zproducts.forEach(function (item, i) {
          if (item.videos.length > 0) {
            zproducts.splice(i, 1);
            zproducts.unshift(item);
          }
        });
        zproducts.forEach(function (item, i) {
          if (item.vr_url) {
            zproducts.splice(i, 1);
            zproducts.unshift(item);
          }
        });
        var desc = exdata.company_description;
        var boldtext = '';
        if (desc) {
          if (desc.includes('Please contact us via WECHAT')) {
            boldtext = 'Please contact us via WECHAT' + desc.split('Please contact us via WECHAT')[1];
            desc = desc.split('Please contact us via WECHAT')[0]
          }
        }

        this.setState({
          isPaneOpenBottom: true,
          companyVR: exdata.is_vr,
          companyName: exdata.company_name,
          companyLogo: exdata.company_logo,
          companyDesc: desc,
          wechatBold: boldtext,
          companyHall: exdata.hall,
          companyBooth: exdata.booth_number,
          companyProducts: exdata.products,
          //companyVideos: exdata.videos,
          companySite: exdata.website,
          productCategories: exdata.category,
          companyWhatsapp: exdata.whatsapp,
          companyEmail: exdata.email,
          companyAwards: exdata.awards,
          companyShort: exdata.short_name,
          redirect: exdata.is_redirect,
        });

      })
      .catch(error => {
        this.setState({ error: true, isloaded: true, errordata: error.data });
      })

  }

  handleClose = (e) => {
    e.preventDefault()
    this.setState({ isPaneOpenBottom: false })
  }

  findArrayElementByTitle(array, booth) {
    return array.find((element) => {
      return element.booth === booth;
    })
  }

  componentDidMount() {
    Modals.setAppElement(this.el);
    axios.get(`${process.env.REACT_APP_API_HOST}/api/halls`)
      .then(res => {
        const halls = res.data;
        var chosenhall = halls.filter(obj => {
          return obj.name === this.props.deff
        })
        this.setState({
          isloaded: true,
          thehall: chosenhall,
        });

      })
      .catch(error => {
        this.setState({ error: true, isloaded: true, errordata: error.data });
      })
  }

  render() {

    let ShowHall
    if (this.props.hall === 'no booth') {
      ShowHall = <Hallnine selected={this.props.selected} handleClick={this.handleClick} hall={this.props.hall} />
    } else {
      ShowHall = <Hallwithbooths selected={this.props.selected} handleClick={this.handleClick} hall={this.props.hall} />
    }

    const Hallname = { name: this.props.hall, id: null }

    return <div ref={ref => this.el = ref} >

      {/* <Hallnumber selected={this.props.selected} handleClick={this.handleClick} hallx={this.state.thehall}/> */}
      {ShowHall}

      <SlidingPane
        isOpen={this.state.isPaneOpenBottom}
        title={this.state.companyBooth}
        from='bottom'
        width='100%'
        onRequestClose={() => this.setState({ isPaneOpenBottom: false })}>
        <div className="d-flex p-4 slide-top-div text-left">
          <button className="close-btn" onClick={this.handleClose}><CloseIcon /></button>
          <div className="pr-4">
            {this.state.companyLogo && <img className="slide-logo border-0" src={this.state.companyLogo} alt="company logo" height="80px" />}
            {!this.state.companyLogo && <img className="slide-logo border-0" src={Placeholder} alt="company logo" height="80px" />}
          </div>
          <div className="d-flex align-items-center">
            <div className="mr-2">
              <div className="slide-title text-weight-bold">{this.state.companyName}</div>
              <div className="slide-company-desc">HALL {this.state.companyBooth}</div>
            </div>
            {this.state.companyVR === 1 && <Eggthemighty />}
          </div>
        </div>

        <Tabs defaultActiveKey="home" id="uncontrolled-tab-example" className="tab-title">
          <Tab eventKey="home" title="Company Profile">
            <div className="tab-content-subtitle">Company Description</div>
            <div className="mb-5">{this.state.companyDesc} <b style={{ letterSpacing: '0.01em', fontSize: 14 }}>{this.state.wechatBold}</b></div>
            {this.state.companySite && <div className="visit-site"><a href={this.state.companySite} className="visit-href" target="_blank" rel="noopener noreferrer">Visit Website &gt;</a></div>}
            {this.state.redirect === 0 && <div className="tab-content-subtitle border-top">Product Category</div>}
            {this.state.redirect === 0 && <Productcategory cateprops={this.state.productCategories} />}
            {this.state.redirect === 0 && this.state.companyAwards.length !== 0 && <div className="tab-content-subtitle border-top">EFE Awards</div>}
            {this.state.redirect === 0 && this.state.companyAwards.length === 0 && <div className="tab-content-subtitle border-top mb-5">EFE Awards</div>}
            {this.state.redirect === 0 && this.state.companyAwards.length !== 0 && <Awardslist awards={this.state.companyAwards} cname={this.state.companyName} hall={Hallname} />}
          </Tab>
          {this.state.redirect === 0 && <Tab eventKey="profile" title={this.state.companyVR ? <><span>Products</span>{/* <img src={wow360} alt="WOW 360" height="30" className="ml-2" /> */}</> : "Products"}>
            <Productlist products={this.state.companyProducts} cname={this.state.companyName} hall={Hallname} />
          </Tab>}
        </Tabs>
        {this.state.redirect === 0 && <div className="enq-box border-top row">
          {this.state.companyEmail && <a href={"mailto: " + this.state.companyEmail} id={this.state.companyShort} target="_blank" rel="noopener noreferrer" className="col-6 border-right" style={{ color: '#EC2034' }}>Email Enquiry</a>}
          {this.state.companyWhatsapp && this.state.companyWhatsapp[0] === '0' && <a href={"https://wa.me/6" + this.state.companyWhatsapp} id={this.state.companyShort} target="_blank" rel="noopener noreferrer" className="col-6" style={{ color: '#9ACA55' }}>Whatsapp Us</a>}
          {this.state.companyWhatsapp && this.state.companyWhatsapp[0] !== '0' && <a href={"https://wa.me/" + this.state.companyWhatsapp} id={this.state.companyShort} target="_blank" rel="noopener noreferrer" className="col-6" style={{ color: '#9ACA55' }}>Whatsapp Us</a>}
        </div>}

      </SlidingPane>

    </div>

  };
}

export default Floor;
